import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import CoworkerCard from '../components/CoworkerCard';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 30,
  },
  media: {
    height: 300,
    paddingTop: "46.25%",
  },
}));

function CoWorkers({ coWorkers }) {
  const classes = useStyles();
  
  useEffect(() => {
    document.title = "Medarbetare | Advokatbyrån Pars"
  }, [])

  return (
    <Container className={classes.container}>
      <Grid container spacing={4} justifyContent="center">
        {coWorkers.map(cw => (<Grid item key={cw.id} xs={12} md={3}><CoworkerCard coworker={cw} /></Grid>))}
      </Grid>
    </Container>
  )
}

export default CoWorkers
