import React, { useState } from 'react';
import { Grid, TextField, Button, Typography, Modal, Box } from '@material-ui/core';
import emailjs from 'emailjs-com';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "none"
  },
  button: {
    backgroundColor: theme.palette.text.primary,
    color: "white",
  },
  textfield: {
    backgroundColor: "#fbf8f2"
  },
  grid: {
    width: "max"
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid',
  borderColor: '#c89d6a',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4
}

function ContactForm() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  function onSubmit(e) {
    e.preventDefault();
    emailjs.sendForm('service_upf7mcv', 'contact_form', e.target, 'user_t1NDvOnfZxlNnceYbWaNg')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    setOpen(true);
  }


  return (
    <>
      <Grid className={classes.grid}>
        <Typography gutterBottom variant="h5">
          KONTAKT
        </Typography>
        <Typography gutterBottom color="secondary">Kontakta oss för en 15 min kostnadsfri rådgivning. Vi svarar inom 24 timmar.</Typography>
        <form onSubmit={onSubmit}>
          <Grid container spacing={1}>
            <Grid xs={12} item>
              <input className={classes.contactNumber} hidden type="text" name="contact_number"></input>
              <TextField className={classes.textfield} label="Namn" variant="outlined" fullWidth required name="user_name" />
            </Grid>
            <Grid item xs={12}>
              <TextField className={classes.textfield} type="email" label="Email" variant="outlined" fullWidth required name="user_email" />
            </Grid>
            <Grid item xs={12}>
              <TextField className={classes.textfield} label="Meddelande" multiline rows={4} variant="outlined" fullWidth required name="message" />
            </Grid>
            <Grid item xs={12}>
              <Button className={classes.button} disableElevation type="submit" variant="contained" fullWidth>Skicka</Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Modal
        open={open}
        onClose={setOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography color="secondary" gutterBottom id="modal-modal-title" variant="h6" component="h2">
            Tack för att du kontaktar oss!
          </Typography>
          <Typography color="secondary" paragraph id="modal-modal-description" sx={{ mt: 2 }}>
            Vi svarar inom 24 timmar.
          </Typography>
          <Button onClick={() => setOpen(false)} className={classes.button} disableElevation variant="contained" fullWidth>Stäng</Button>
        </Box>
      </Modal>
    </>
  );
}

export default ContactForm;


