import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { Call, Email } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({

  container: {
    paddingTop: 30,
  },
  title: {
    marginBottom: 15
  },
  media: {
    [theme.breakpoints.down("md")]: {
      minHeight: 500,
    },
    minHeight: 360,
    paddingTop: "56.25%",
  },
  tfn: {
    fontWeight: "bold",
    marginTop: "3vh",
  },
  link: {
    textDecoration: "none",
  },
  icon: {
    marginRight: 5,
  },
  typography: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  }
}));


function CoWorkerDetails({ coWorker, children }) {
  const classes = useStyles();

  const {
    firstName,
    lastName,
    title,
    phone,
    email
  } = coWorker;

  return (
    <Container className={classes.container}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardMedia
              className={classes.media}
              image={`images/${firstName}.jpg`}
            />
          </Card>
        </Grid >
        <Grid item xs={12} md={9}>
          <Typography className={classes.grid} variant="h4">{firstName} {lastName}</Typography>
          <Typography gutterBottom className={classes.title} variant="subtitle1">{title}</Typography>
          <Typography paragraph className={classes.grid} color="secondary">{children}</Typography>
          <a className={classes.link} href={`tel:${phone}`}>
            <Typography paragraph color="secondary" className={classes.typography}><Call className={classes.icon} />{phone}</Typography>
          </a>
          <a className={classes.link} href={`mailto:${email}`}>
            <Typography paragraph color="secondary" className={classes.typography}><Email className={classes.icon} />{email}</Typography>
          </a>
        </Grid>
      </Grid>
    </Container>
  )
}


export default CoWorkerDetails;