import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

import {
  List,
  ListItem,
  IconButton,
  makeStyles,
  Drawer,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

const DrawerComponent = () => {
  const useStyles = makeStyles((theme) => ({
    drawerContainer: {},
    iconButtonContainer: {
      marginLeft: "auto",
      color: theme.palette.text.primary,
    },

    menuIconToggle: {
      fontSize: "3rem",
    },
    link: {
      textDecoration: "none",
      color: "inherit",
    },
    button: {
      marginRight: 20,
      justifyContent: "left"
    }
  }));

  const [openDrawer, setOpenDrawer] = useState(false);

  //Css
  const classes = useStyles();
  return (
    <>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawerContainer }}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
      >
        <List>
          <ListItem divider button onClick={() => setOpenDrawer(false)}>
            <Link className={classes.link} to="/">
              <Button className={classes.button}>Hem</Button>
            </Link>
          </ListItem>
          <ListItem divider button onClick={() => setOpenDrawer(false)}>
            <Link className={classes.link} to="/rattsomraden">
              <Button>Rättsområden</Button>
            </Link>
          </ListItem>

          <ListItem divider button onClick={() => setOpenDrawer(false)}>
            <Link className={classes.link} to="/medarbetare">
              <Button>Medarbetare</Button>
            </Link>
          </ListItem>

          <ListItem divider button onClick={() => setOpenDrawer(false)}>
            <Link className={classes.link} to="/kontakt">
              <Button>Kontakt</Button>
            </Link>
          </ListItem>
        </List>
      </Drawer>
      <IconButton
        className={classes.iconButtonContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <MenuIcon className={classes.menuIconToggle} />
      </IconButton>
    </>
  );
};

export default DrawerComponent;
