import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Contactform from "../components/Contactform";


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 80,
    paddingBottom: 60,
  },

  contact: {
    marginTop: 50,
  },

  sthlm: {
    marginTop: 50,
  },

  form: {
    width: "100%",
    marginTop: 50,
  },
}));

function Contact() {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Kontakt | Advokatbyrån Pars"
  }, [])

  return (
    <Container>
      <Grid container className={classes.container}>
        <Grid item md={12}>
          <Contactform></Contactform>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Contact;
