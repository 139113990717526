import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { Call, Email } from "@material-ui/icons";
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#fbf8f2",
    [theme.breakpoints.down("md")]: {
      height: 735,
    },
  },
  media: {
    [theme.breakpoints.down("md")]: {
      minHeight: 500,
    },
    minHeight: 360,
    paddingTop: "56.25%",
  },
  button: {
    width: "100%",
    backgroundColor: theme.palette.text.primary,
    color: "white",
    fontWeight: "bold",
    fontSize: 14
  },
  link: {
    textDecoration: "none",
  },
  typography: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
  tfn: {
  },
  title: {
    color: "black",
  },
  icon: {
    marginRight: 5,
  }
}));

function CoworkerCard({ coworker }) {
  const classes = useStyles();


  const gotoServices = () =>
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  return (
    <div>
      <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image={`images/${coworker.firstName}.jpg`}
        />
        <CardHeader title={`${coworker.firstName} ${coworker.lastName}`} subheader={coworker.title} titleTypographyProps={{ color: "secondary" }} subheaderTypographyProps={{ color: "secondary" }} />
        <CardContent>
          <a className={classes.link} href={`tel:${coworker.phone}`}>
            <Typography paragraph color="secondary" className={classes.typography}><Call className={classes.icon} />{coworker.phone}</Typography>
          </a>
          <a className={classes.link} href={`mailto:${coworker.email}`}>
            <Typography paragraph color="secondary" className={classes.typography}><Email className={classes.icon} />{coworker.email}</Typography>
          </a>
          <Link onClick={gotoServices} className={classes.link} to={coworker.firstName}><Button className={classes.button} disableElevation variant="contained">Läs mer om {coworker.firstName}</Button></Link>
        </CardContent>
      </Card>
    </div>
  )
}

export default CoworkerCard
