import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { useEffect, useState, lazy, Suspense } from 'react';
import Home from './pages/Home';
import Coworkers from './pages/Coworkers'
import Navbar from './components/Navbar'
import Aboutus from './pages/Aboutus'
import Contact from './pages/Contact'
import Stickyfooter from './components/Stickyfooter'
import CoWorkerDetails from './components/CoWorkerDetails'
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "white",
  }
}));

function App() {
  const [coWorkers, setCoWorkers] = useState([])

  useEffect(() => {
    fetch('api/employees')
      .then(res => res.json())
      .then(data => setCoWorkers(data))
  }, [])

  const classes = useStyles();
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <Box sx={{overflowX:"hidden"}}>
          <Navbar />
          <div className={classes.content}>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/sv">
                <Home />
              </Route>
              <Route path="/medarbetare">
                <Coworkers coWorkers={coWorkers} />
              </Route>
              <Route path="/rattsomraden">
                <Aboutus />
              </Route>
              <Route path="/kontakt">
                <Contact />
              </Route>
              {coWorkers.map(coWorker => {
                const CoWorkerData = lazy(() => import(`./links/${coWorker.firstName}.js`));
                return (
                  <Route key={coWorker.id} path={`/${coWorker.firstName}`}>
                    <CoWorkerDetails coWorker={coWorker}>
                      <CoWorkerData />
                    </CoWorkerDetails >
                  </Route>
                )
              })}
            </Switch>
          </div>
          <Stickyfooter />
        </Box>
      </Router>
    </Suspense>


  );
}

export default App;
