import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import Logopars from "../adds/img/Logo pars.png";
import LogoparsMob from "../adds/img/Logo pars mobile.png";
import { useMediaQuery, useTheme } from "@material-ui/core";
import DrawerComponent from "./DrawerComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "sticky",
    zIndex: 5,
    top: 0,
    bottom: 20,
    paddingtop: 40,
  },

  title: {
    flexGrow: 1,
  },

  link: {
    textDecoration: "none",
    color: "inherit",
  },

  appbar: {
    [theme.breakpoints.up("md")]: {
      paddingRight: 100,
      paddingLeft: 100,
    },
    backgroundColor: theme.background,
  },

  button: {
    marginLeft: 50,
  },

  contactBtn: {
    border: 10,
  },

  img: {
    position: "relative",
    top: "2px",
  },

  toolbar: {
    minHeight: 80,
    paddingRight: 0,
    paddingLeft: 0,
    justifyContent: "space-between"
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const isLogoMobile = isMatch ? LogoparsMob : Logopars;

  const gotoServices = () =>
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar}>
        <Container maxWidth="xl">
          <Toolbar className={classes.toolbar}>
            <Link onClick={gotoServices} className={classes.link} to="/">
              <img className={classes.img} src={isLogoMobile} alt="logo" />
            </Link>
            {isMatch ? (
              <>
                <DrawerComponent />
              </>
            ) : (
              <>
                <div>
                  <Link className={classes.link} to="/rattsomraden">
                    <Button onClick={gotoServices} className={classes.button}>
                      <Typography variant="h6">
                        Rättsområden
                      </Typography>
                    </Button>
                  </Link>
                  <Link className={classes.link} to="/medarbetare">
                    <Button onClick={gotoServices} className={classes.button}>
                      <Typography variant="h6">
                        Medarbetare
                      </Typography>
                    </Button>
                  </Link>
                  <Link className={classes.link} to="/kontakt">
                    <Button onClick={gotoServices} className={classes.button}>
                      <Typography variant="h6">
                        Kontakt
                      </Typography>
                    </Button>
                  </Link>
                </div>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
