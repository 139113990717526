import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Call, Email, LocationOn } from "@material-ui/icons";

const useStyles = makeStyles({
  typography: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: 5
  },
  link: {
    textDecoration: 'none'
  }
});



function ContactUs(props) {
  const classes = useStyles();

  return (
    <>
      <Typography paragraph variant="h5">{props.city}</Typography>
      <Typography paragraph color="secondary" className={classes.typography}><LocationOn className={classes.icon} />{props.address}, {props.zipcode} {props.city}</Typography>
      <a className={classes.link} href={`tel:${props.phone}`}>
        <Typography paragraph color="secondary" className={classes.typography}><Call className={classes.icon} />{props.phone}</Typography>
      </a>
      <a className={classes.link} href={`mailto:${props.email}`}>
        <Typography paragraph color="secondary" className={classes.typography}><Email className={classes.icon} />{props.email}</Typography>
      </a>
    </>
  )
}

export default ContactUs