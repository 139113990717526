import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardHeader,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    padding: 0,
  },
  card: {
    minHeight: 520,
    backgroundColor: "#fbf8f2"
  },
  typography: {
    marginBottom: 25
  }
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography className={classes.typography} variant="h4" gutterBottom>
        Press
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <a
            href="https://unt.se/uppland/uppsala/hon-ar-en-jurist-med-patos-308243.aspx"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Card className={classes.card} sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image="images/fati.jpg"
                  alt="green iguana"
                />
                <CardHeader
                  title="Hon är en jurist med patos"
                  subheader="Hon håller gratis juridisk rådgivning på Stenhagens bibliotek och till hösten öppnar hon juristjour för utsatta för hedersvåld.
                - Jag har alltid haft en ideell ådra, säger Fati Pakyari, jurist och Uppsalabo."
                  titleTypographyProps={{ color:"secondary", variant: "h6", gutterBottom: true }}
                  subheaderTypographyProps={{ color: "secondary", paragraph: true }} />
              </CardActionArea>
            </Card>
          </a>
        </Grid>
        <Grid item xs={12} md={4}>
          <a
            href="https://bloggar.aftonbladet.se/oppengranskningmigrationsverket/2012/10/nuhami-far-stanna-mamma-och-pappa-utvisas/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Card className={classes.card} sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image="images/nuhami.jpg"
                  alt="green iguana"
                />
                <CardHeader
                  title="Nuhami får stanna i sverige – Mamma och pappa utvisas"
                  subheader="Nuhami är en liten flicka som Migrationsverket gett uppehållstillstånd.
                Men mamma och pappa ska utvisas till Etiopien."
                  titleTypographyProps={{ color:"secondary", variant: "h6", gutterBottom: true }}
                  subheaderTypographyProps={{ color: "secondary", paragraph: true }} />

              </CardActionArea>
            </Card>
          </a>
        </Grid>
        <Grid item xs={12} md={4}>
          <a
            href="https://www.enterprisemagazine.se/nyheter/jurister-i-samhallets-tjanst-1"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Card className={classes.card} sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image="images/samhällstjänst.jpg"
                  alt="green iguana"
                />
                <CardHeader
                  title="Jurister i samhällstjänst"
                  subheader="Med ett centralt läge i Kista företagscenter arbetar juristerna på Pars Juristbyrå med det övergripande målet att undvika konflikt.
                  De tar sitt yrke på största allvar och ser juristens roll i samhället som samhällsbärande."
                  titleTypographyProps={{ color:"secondary", variant: "h6", gutterBottom: true }}
                  subheaderTypographyProps={{ color: "secondary", paragraph: true }} />

              </CardActionArea>
            </Card>
          </a>
        </Grid>
      </Grid>
    </Box >
  );
}
