import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Container } from "@material-ui/core";
import lagbok from "../adds/img/lagbok.png";
import { useMediaQuery, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    paddingTop: "40px",
    textTransform: "uppercase",
  },

  text: {
    textAlign: "left",
  },

  divider: {
    marginTop: 30,
  },
  container: {
    paddingBottom: 30
  },
}));

export default function CenteredGrid() {
  const classes = useStyles();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    document.title = "Rättsområden | Advokatbyrån Pars"
  }, [])

  return (
    <Container className={classes.container}>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography className={classes.title} variant="h5">
            {" "}
            BROTTMÅL - EKOBROTT{" "}
          </Typography>
          <Typography className={classes.text} color="secondary">
            Försvarsadvokat för personer som är misstänkta för brott. Du har
            rätt att begära försvarsadvokat som står på din sida och kämpar för
            dig. Staten står för kostnaden. Målsägandebiträde för personer som
            blivit utsatta för brott. Du har rätt att begära ditt eget
            målsägandebiträde.
          </Typography>

          <Typography className={classes.title} variant="h5">
            {" "}
            ARBETSRÄTT{" "}
          </Typography>
          <Typography className={classes.text} color="secondary">
            Vi bistår både enskilda arbetstagare och arbetsgivare i alla sorters
            arbetsrättsliga ärenden. Vi kan hjälpa arbetstagare med rådgivning
            och biträde vid exempelvis felaktig uppsägning, olaga avskedande,
            lönefordringar eller diskrimineringsärenden. Vi biträder
            arbetstagare och arbetsgivare vid domstolstvister, avtalstolkning,
            personärenden och rådgivning.
          </Typography>

          <Typography className={classes.title} variant="h5">
            {" "}
            FAMILJERÄTT{" "}
          </Typography>
          <Typography className={classes.text} color="secondary">
            Bodelning, bodelning rörande egendom i Iran, ansökan om
            äktenskapsskillnad, ansökan om äktenskapsskillnad i Iran,
            kvarsittningsrätt, vårdnad, boende och umgänge med barn m.m.
          </Typography>

          <Typography className={classes.title} variant="h5">
            {" "}
            MIGRATIONSRÄTT{" "}
          </Typography>
          <Typography className={classes.text} color="secondary">
            Asyl, arbetstillstånd, uppehållstillstånd (business) medborgarskap
            m.m
          </Typography>

          <Divider className={classes.divider}></Divider>

          <Typography className={classes.title} variant="h5">
            {" "}
            AFFÄRSJURIDIK{" "}
          </Typography>
          <Typography className={classes.text} color="secondary">
            Vi biträder företag och enskilda personer i ärenden som rör affärstvister.
          </Typography>

          <Typography className={classes.title} variant="h5">
            {" "}
            LVU{" "}
          </Typography>
          <Typography className={classes.text} color="secondary">
            Har socialen tvångsomhändertagit ditt barn? Du har rätt att önska
            din egen advokat som kan hjälpa dig. Staten står för kostnaden. Vi
            hjälper dig gärna.
          </Typography>

          <Typography className={classes.title} variant="h5">
            {" "}
            FORDRINGSRÄTT{" "}
          </Typography>
          <Typography className={classes.text} color="secondary">
            Om en person inte vill betala tillbaka dig kan vi hjälpa dig med att
            få dina pengar tillbaka.
          </Typography>

          <Typography className={classes.title} variant="h5">
            HYRES-OCH FASTIGHETSRÄTT
          </Typography>
          <Typography className={classes.text} color="secondary">
            Fel i fastighet, bygg-och renoveringsfel. Vi biträder även i
            uppsägnings- och förlängningstvister.
          </Typography>
        </Grid>
        {isMatch ? (
          <></>
        ) : (
          <Grid item md={6}>
            <img className={classes.title} src={lagbok} width="700" height="600" alt="lagbok"></img>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
