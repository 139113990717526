import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Carousel from "react-material-ui-carousel";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Press from '../components/Press'
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
  media: {
    paddingTop: "40%",
  },
  container: {
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
      marginLeft: 0,
    },
    paddingTop: 20,
  },
  italic: {
    fontStyle: "italic",
  },
  divider: {
    marginTop: 50,
    marginBottom: 40,
  },
  contentImage: {
    paddingTop: "80%"
  }
}));

function Home() {
  useEffect(() => {
    document.title = "Advokatbyrån Pars"
  }, [])

  const classes = useStyles();
  return (
    <div className="hero-image">
      <Carousel timeout={1500} interval={15000} autoPlay>
        <Card>
          <CardMedia
            className={classes.media}
            image="images/stockholm_hero_img.jpg"
            alt="stockholm_hero"
          />
        </Card>
        <Card>
          <CardMedia
            className={classes.media}
            image="images/tehran_hero_img.jpg"
            alt="tehran_hero"
          />
        </Card>
      </Carousel>
      <Container className={classes.container}>
        <Grid container>
          <Grid item md={7}>
            <Typography variant="h4" className={clsx(classes.container, classes.italic)}>Rättvisa och frihet</Typography>
            <Typography className={classes.container} color="secondary">
              <b>Pars</b> kommer av det persiska namnet på det antika Iran. En fredlig stormakt där kungen Kourosh stiftade de första kända mänskliga rättigheterna.
              Vi kämpar för dina mänskliga rättigheter då vi tror på människans rätt att få vara fri.
              Fri från förtryck, fri från styrande maktsamhälle och fri att få leva sitt liv med respekt för sin medmänniska.
              <br /> 
              <br /> 
              Advokatbyrån Pars har hjälpt
              privatpersoner och företag att finna de lösningar som passar varje
              enskilt ärende bäst. Byrån drivs av medmänsklighet och fokus ligger på
              att kämpa för Dina rättigheter. Vårt motto är att behandla våra klienter
              såsom vi själva skulle vilja bli behandlade om vi befann oss i en
              liknande situation och det tänkespråket har resulterat i framgång för
              våra klienter.
              <br />  <br />
              Vi är vidare den enda advokatbyrån i Sverige med en
              verksam jurist med iransk advokatexamen. Genom våra väletablerade
              kontakter med advokatbyråer i Iran har vi de redskap som krävs för
              att på bästa sätt hjälpa både företag och privatpersoner med
              gränsöverskridande angelägenheter.
              <br />  <br />
              Är du missnöjd med en faktura eller
              har annan ekonomisk fråga kan du vända dig till Advokatsamfundets
              konsumenttvistnämnd.{" "}
            </Typography>
          </Grid>
          <Grid item md={5}>
            <CardMedia
              className={classes.contentImage}
              image="images/justice2.jpg"
              alt="tehran_hero"
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider}></Divider>
        <Press />
      </Container>
    </div>
  );
}

export default Home;
