import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Logopars from "../adds/img/footer logo.png";
import { useMediaQuery, useTheme } from "@material-ui/core";
import ContactUs from "./ContactUs";
import clsx from 'clsx';
import Constants from "../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 0,
    marginLeft: 0,
    backgroundColor: "white"
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  footer: {
    [theme.breakpoints.down("md")]: {
      height: 360,
      marginTop: "10vh",
    },
    marginTop: "5vh",
    marginBottom: "5vh",
    display: "flex",
    paddingLeft: 0,
    marginLeft: 0,
    alignItems: "center",
  },

  container: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },

  instagram: {
    color: "purple",
    position: "relative",
  },

  facebook: {
    color: "rgb(25, 118, 210)",
  },

  btn: {
    padding: 0,
    '& svg': {
      fontSize: 75
    },
  },

  contact: {
    marginTop: 20,
    marginBottom: 20,
  },

  box: {
    width: 350
  },

  logo: {
    textAlign: "center"
  },

  socialMediaIcons: {
    [theme.breakpoints.up("md")]: {
      textAlign: "end"
    },
  }
}));

export default function StickyFooter() {
  const classes = useStyles();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className={classes.root}>
      <CssBaseline />
      <footer className={classes.footer}>
        <Container className={classes.container}>
          <Box className={classes.box}>
            <div className={classes.contact}>
              <ContactUs
                city={Constants.CITY_STOCKHOLM}
                address={Constants.ADDRESS_STOCKHOLM}
                zipcode={Constants.ZIPCODE_STOCKHOLM}
                phone={Constants.PHONE_STOCKHOLM}
                email={Constants.CONTACT_EMAIL}
              />
            </div>
          </Box>
          {isMatch ? null : (
            <Box className={clsx(classes.logo, classes.box)}>
              <img src={Logopars} alt="logopars" />
            </Box>
          )}
          <Box className={clsx(classes.box, classes.socialMediaIcons)}>
            <IconButton
              href="https://www.facebook.com/advokatpars.se/"
              target="_blank"
              className={classes.btn}
            >
              <FacebookIcon fontSize="large" className={classes.facebook}></FacebookIcon>
            </IconButton>
            <IconButton
              href="https://www.instagram.com/advokat_fatemeh_pakyari/"
              target="_blank"
              className={classes.btn}
            >
              <InstagramIcon
                className={classes.instagram}
              ></InstagramIcon>
            </IconButton>
          </Box>
        </Container>
      </footer>
    </div>
  );
}
